<template>
	<v-sheet v-if="incentiveOnly">
		<project-intro :id="project.id" intro="This page shows information about incentives"></project-intro>
		<navigation :id="id"></navigation>
        <v-simple-table>
            <thead>
                <tr>
                    <th>Created</th>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Amazon Voucher</th>
                    <th>Details Received</th>
                    <th>Payment By</th>
                    <th>Paid</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, i) in rows" :key="i">
                    <td>{{createdDate(row)}}</td>
                    <td>{{row.first_name}} {{row.last_name}}</td>
                    <td>{{row.email}}</td>
                    <td>{{row.amount}}</td>
                    <td>{{row.status}}</td>
                    <td>{{row.amazon ? 'True' : ''}}</td>
                    <td>{{row.ready_to_pay_at ? row.ready_to_pay_at.toDate().toLocaleDateString() : ""}}</td>
                    <td>{{row.ready_to_pay_at ? mwtimeutils.in10WorkingDays(row.ready_to_pay_at.toDate()).toLocaleDateString() : ""}}</td>           
                    <td>{{row.date_paid ? row.date_paid.toDate().toLocaleDateString() : ""}}</td>    
                    </tr>
            </tbody>
        </v-simple-table>
    </v-sheet>
</template>

<script>
import ProjectIntro from "@c/project/ProjectIntro";
import Navigation from "@c/incentives/Navigation";
export default {
    name: "ViewIncentives",
    props: {
        id: {type: String }
    },
	components: {
		Navigation,
        ProjectIntro
	},
    computed: {
        submissions(){
            return this.$store.getters.getByKey("incentiveSubmissions", "project", this.id )
        }, 
        rows(){
            let rows = this.submissions;
            rows = this.mwutils.sortByDate(rows, "created_at", -1);
            return rows;
        },
		project(){
			return this.$store.state.projects.data[this.id];
		},
        incentiveOnly(){
            return this.project && this.project.incentive_only;
        }
    },
    methods: {
        createdDate(row){
            if( !row.created_at ){
                return ""
            }
            let d = row.created_at;
            if(d.toDate){
                d = d.toDate();
            }
            return d.toLocaleDateString();
        }
    },
    created(){
        this.$store.dispatch("incentiveSubmissions/fetchByProject", this.id )
        this.$store.dispatch("projects/fetchById", this.id )
    }
}
</script>